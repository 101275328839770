/* import __COLOCATED_TEMPLATE__ from './blinking-dot.hbs'; */
import Component from '@glimmer/component';

interface Signature {
  Element: HTMLDivElement;

  Args: {
    color: 'gray' | 'green' | 'red' | 'yellow';
  };
}

export default class BlinkingDotComponent extends Component<Signature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    BlinkingDot: typeof BlinkingDotComponent;
  }
}
